<template>
  <section id="hero">
    <v-sheet dark tile>
      <v-img
        :src="require('@/assets/Main/wholesale.webp')"
        :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
        :gradient="gradient"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-row>
        </template>
        <v-container
          light
          fill-height
          fluid
          :px-12="$vuetify.breakpoint.mdAndUp"
        >
          <v-row align="center" justify="start">
            <v-slide-x-reverse-transition appear>
              <v-col cols="12" md="7" ma="0">
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <h1
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                    ]"
                    class="font-weight-bold"
                  >
                    Specials
                  </h1>

                  <v-divider class="mb-3" />

                  <div class="subheading mb-4">
                    <p>
                      <em>The more the merrier!</em>
                    </p>
                  </div>
                  <v-row>
                    <!-- <v-btn
                      href="#details"
                      color="thirdley"
                      class="ml-3"
                      tile
                      outlined
                    >
                      Details&nbsp;
                      <v-icon>{{ svgArrow }}</v-icon>
                    </v-btn> -->
                  </v-row>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-container>
      </v-img>
    </v-sheet>
  </section>
</template>

<style scoped>
#scroll_more {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "HomeHero",
  components: {
    // Boasters: () => import('@/components/Boasters.vue')
  },
  props: {
    gradient: {
      type: String,
      default: "to top, rgba(0,0,0,.18), rgba(0,0,0,.18)"
    }
  }
};
</script>
